<template>
  <div
    class="the-navbar__user-meta flex items-center"
    v-if="user.name"
  >
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="ml-3">
        <profile-picture
          :width="40"
          :height="40"
          :user-name="user.name"
          :profile-pic="user.avatar" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul class="p-2" style="width: 22rem">
          <!-- <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/profile').catch(() => {})"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li> -->

          <!-- <vs-divider class="m-1" /> -->
          <li>
            <vs-row class="text-left flex flex-column place-items-center justify-start flex-wrap">
              <vs-col vs-w="3" class="pr-3">
                <profile-picture
                  :width="72"
                  :height="72"
                  :user-name="user.name"
                  :profile-pic="user.avatar" />
              </vs-col>
              <vs-col vs-w="9" class="pl-3">
                <p class="font-semibold truncate">{{ user.name }}</p>
                <p class="font-light truncate">{{ user.email }}</p>
              </vs-col>
            </vs-row>
          </li>

          <li
            class="flex mt-4 py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import axios from "@/axios";
import ProfilePicture from '@/components/user/ProfilePicture.vue'
import { mapGetters, mapActions } from "vuex";

export default {
  components: { ProfilePicture },
  data() {
    return {
      user: {
        name: '',
        avatar: '',
        email: '',
      }
    };
  },
  computed: {
    ...mapGetters(["isUserLoggedIn"])
  },
  methods: {
    ...mapActions('auth', ['logout', 'clearStorage']),
    me() {
      axios
        .get("/profile")
        .then(({ data: res }) => {
          this.user.name = res.data.user.name;
          this.user.avatar = res.data.profilePic;
          this.user.email = res.data.user.email;
          this.$store.commit('UPDATE_IS_FETCHED', true);
        })
        .catch(err => {
          if (err.status === 401) {
            this.clearStorage();
            this.$router.push({ path: "/auth/login" });
            this.$vs.notify({
              title: "Unauthorized",
              text: err.data.msg_status,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              time: 5000
            });
          }
        });
    }
  },
  mounted() {
    this.me()
  }
};
</script>
<style lang="scss">

</style>
