<template>
  <div>
    <img
      v-if="profilePic && !isError"
      key="onlineImg"
      :src="getProfilePicture"
      alt="user-img"
      :style="{width: `${width}px`, height: `${height}px`}"
      class="rounded-full shadow-md cursor-pointer block"
      @error="isError = true"
    />
    <div v-else
         class="flex items-center justify-center bg-primary p-4 rounded-full"
         :style="{width: `${width}px`, height: `${height}px`}">
      <div class="text-white" :class="placeholderClass">
        {{ userName.split(' ').map(i => i.charAt(0)).join('').slice(0, 2).toUpperCase() }}
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from '@/axios'

export default {
  name: 'ProfilePicture',
  props: ['width', 'height', 'profilePic', 'userName', 'placeholderClass'],
  data() {
    return {
      isError: false,
    }
  },
  computed: {
    getProfilePicture() {
      return baseURL + '/profile/images/' + this.profilePic;
    }
  }
}
</script>

<style scoped>

</style>
