/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------*/
export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard'
  },
  {
    url: '/companies',
    name: 'Companies',
    icon: 'HomeIcon',
    i18n: 'Companies'
  },
  {
    url: '/roles',
    name: 'Roles',
    icon: 'KeyIcon',
    i18n: 'Roles'
  },
  {
    url: '/users',
    name: 'Users',
    icon: 'UsersIcon',
    i18n: 'Users'
  },
  {
    url: '/brands',
    name: 'Brands',
    icon: 'BoxIcon',
    i18n: 'Brands'
  },
  {
    url: '/promo-categories',
    name: 'PromoCategories',
    icon: 'BoxIcon',
    i18n: 'PromoCategories'
  },
  {
    url: '/promos',
    name: 'Promo',
    icon: 'PercentIcon',
    i18n: 'Promo'
  },
  {
    url: '/features',
    name: 'Feature',
    icon: 'StarIcon',
    i18n: 'Feature'
  },
  {
    url: null,
    name: 'CompanyContent',
    icon: 'BookOpenIcon',
    i18n: 'CompanyContent',
    submenu: [
      {
        url: '/company-content/partners',
        name: 'Partners',
        slug: 'partner',
        i18n: 'Partners'
      },
      {
        url: '/company-content/social-media',
        name: 'SocialMedia',
        slug: 'social-media',
        i18n: 'SocialMedia'
      },
      {
        url: '/company-content/faq',
        name: 'FAQ',
        slug: 'faq',
        i18n: 'FAQ'
      }
    ]
  },
  {
    url: null,
    name: 'MenuItem',
    icon: 'ListIcon',
    i18n: 'MenuItem',
    submenu: [
      {
        url: '/menu-items/sidebar',
        name: 'SidebarMenuItem',
        slug: 'menu-item-sidebar',
        i18n: 'Sidebar'
      },
      {
        url: '/menu-items/navbar',
        name: 'NavbarMenuItem',
        slug: 'menu-item-navbar',
        i18n: 'Navbar'
      },
      {
        url: '/menu-items/admin',
        name: 'AdminMenuItem',
        slug: 'menu-item-admin',
        i18n: 'AdminMenu'
      }
    ]
  },
  {
    url: '/user-settings',
    name: 'User Setting',
    icon: 'SettingsIcon',
    i18n: 'UserSettings'
  }
]

